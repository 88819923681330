import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-grid" }
const _hoisted_5 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_6 = { for: "gender" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 2,
  class: "p-invalid"
}
const _hoisted_9 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_10 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_11 = { for: "dietType" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 2,
  class: "p-invalid"
}
const _hoisted_14 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_15 = { for: "weight" }
const _hoisted_16 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_17 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_18 = { for: "intolerances" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_21 = { for: "currentCalories" }
const _hoisted_22 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_23 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_24 = { for: "height" }
const _hoisted_25 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_26 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_27 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_28 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_29 = { for: "birthdate" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 2,
  class: "p-invalid"
}
const _hoisted_32 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_33 = { for: "activityFrequency" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = {
  key: 2,
  class: "p-invalid"
}
const _hoisted_36 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_37 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_38 = { for: "age" }
const _hoisted_39 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_40 = { for: "totalCalories" }
const _hoisted_41 = { class: "p-field p-col-4 p-fluid" }
const _hoisted_42 = { for: "numberOfMeals" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  key: 2,
  class: "p-invalid"
}
const _hoisted_45 = {
  key: 1,
  class: "card-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_NutritionalPlanConfirmModal = _resolveComponent("NutritionalPlanConfirmModal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeadingComponent, {
        title: _ctx.$t('nutrition.nutritionalPlan.dataPatientTitle'),
        divider: false,
        "sub-heading": true
      }, {
        right: _withCtx(() => [
          _renderSlot(_ctx.$slots, "headingActions", {}, () => [
            _withDirectives((_openBlock(), _createBlock(_component_Button, {
              disabled: !_ctx.userCanEditNutritionalPlan,
              class: _normalizeClass(["p-button-rounded p-button-only-icon p-button-only-icon--dark", _ctx.editMode ? 'p-disabled' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editMode = !_ctx.editMode))
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: "icon-edit",
                  "aria-label": _ctx.$t('common.edit'),
                  "aria-hidden": "true"
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["disabled", "class"])), [
              [
                _directive_tooltip,
                _ctx.$t('common.edit'),
                void 0,
                { bottom: true }
              ]
            ])
          ], true)
        ]),
        _: 3
      }, 8, ["title"]),
      (_ctx.nutritionalPlanFormDto)
        ? (_openBlock(), _createElementBlock("form", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('patient.sex')), 1),
                (!_ctx.nutritionalPlanFormDto.gender && !_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, "-"))
                  : (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 1,
                      id: "gender",
                      modelValue: _ctx.nutritionalPlanFormDto.gender,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nutritionalPlanFormDto.gender) = $event)),
                      options: _ctx.genderOptions,
                      "option-label": "label",
                      "option-value": "value",
                      class: _normalizeClass({
                'no-editable': !_ctx.editMode,
                'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.gender.valid,
              })
                    }, null, 8, ["modelValue", "options", "class"])),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.gender.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.gender.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('personData.imc')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.imc), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('patient.dietType')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                (!_ctx.nutritionalPlanFormDto.dietType && !_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_12, "-"))
                  : (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 1,
                      id: "dietType",
                      modelValue: _ctx.nutritionalPlanFormDto.dietType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.nutritionalPlanFormDto.dietType) = $event)),
                      options: _ctx.dietTypeOptions,
                      "option-label": "label",
                      "option-value": "value",
                      class: _normalizeClass({
                'no-editable': !_ctx.editMode,
                'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.dietType.valid,
              })
                    }, null, 8, ["modelValue", "options", "class"])),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.dietType.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.dietType.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('personData.weight')) + " (Kg) " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                _createVNode(_component_InputNumber, {
                  id: "weight",
                  modelValue: _ctx.nutritionalPlanFormDto.weight,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nutritionalPlanFormDto.weight) = $event)),
                  class: _normalizeClass({
              'no-editable': !_ctx.editMode,
              'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.weight.valid,
            }),
                  "max-fraction-digits": 2,
                  min: 0
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.weight.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_16, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.weight.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('personData.intolerances')), 1),
                (!_ctx.nutritionalPlanFormDto.foodIntolerances && !_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, "-"))
                  : (_openBlock(), _createBlock(_component_MultiSelect, {
                      key: 1,
                      id: "intolerances",
                      modelValue: _ctx.nutritionalPlanFormDto.foodIntolerances,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nutritionalPlanFormDto.foodIntolerances) = $event)),
                        _cache[5] || (_cache[5] = ($event: any) => (_ctx.onIntolerancesChange()))
                      ],
                      options: _ctx.foodIntoleranceOptions,
                      "option-label": "label",
                      "option-value": "value",
                      class: _normalizeClass(_ctx.editMode ? '' : 'no-editable')
                    }, null, 8, ["modelValue", "options", "class"]))
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('personData.currentCalories')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                _createVNode(_component_InputNumber, {
                  id: "currentCalories",
                  modelValue: _ctx.nutritionalPlanFormDto.currentCalories,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.nutritionalPlanFormDto.currentCalories) = $event)),
                  class: _normalizeClass({
              'no-editable': !_ctx.editMode,
              'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.currentCalories.valid,
            }),
                  "max-fraction-digits": 2,
                  min: 0
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.currentCalories.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_22, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.currentCalories.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('personData.height')) + " (cm) " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                _createVNode(_component_InputNumber, {
                  id: "height",
                  modelValue: _ctx.nutritionalPlanFormDto.height,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.nutritionalPlanFormDto.height) = $event)),
                  class: _normalizeClass({
              'no-editable': !_ctx.editMode,
              'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.height.valid,
            }),
                  "max-fraction-digits": 2,
                  min: 0
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.height.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_25, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.height.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('personData.basalCalories')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.basalCalories), 1)
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('personData.currentDesviation')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.currentDesviation), 1)
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('person.birthdate')), 1),
                (!_ctx.nutritionalPlanFormDto.birthdate && !_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_30, "-"))
                  : (_openBlock(), _createBlock(_component_Calendar, {
                      key: 1,
                      id: "birthdate",
                      modelValue: _ctx.nutritionalPlanFormDto.birthdate,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.nutritionalPlanFormDto.birthdate) = $event)),
                      "year-navigator": true,
                      "year-range": `${_ctx.minRangeYearBirth}:${_ctx.maxRangeYearBirth}`,
                      class: _normalizeClass({
                'no-editable': !_ctx.editMode,
                'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.birthdate.valid,
              })
                    }, null, 8, ["modelValue", "year-range", "class"])),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.birthdate.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_31, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.birthdate.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('personData.activityFrecuency')) + " " + _toDisplayString(_ctx.editMode ? '*' : ''), 1),
                (!_ctx.nutritionalPlanFormDto.activityFrequency && !_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_34, "-"))
                  : (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 1,
                      id: "activityFrequency",
                      modelValue: _ctx.nutritionalPlanFormDto.activityFrequency,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.nutritionalPlanFormDto.activityFrequency) = $event)),
                      options: _ctx.activityFrequencyOptions,
                      "option-label": "label",
                      "option-value": "value",
                      class: _normalizeClass({
                'no-editable': !_ctx.editMode,
                'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.activityFrequency.valid,
              })
                    }, null, 8, ["modelValue", "options", "class"])),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.activityFrequency.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_35, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.activityFrequency.message}`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('personData.deficit') + '/' + _ctx.$t('personData.surplus')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.currentDesviationPercentage) + " %", 1)
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('person.age')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.age), 1)
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('personData.totalCalories')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.totalCalories), 1)
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('personData.numberOfMeals')), 1),
                (!_ctx.nutritionalPlanFormDto.numberOfMeals && !_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_43, "-"))
                  : (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 1,
                      id: "numberOfMeals",
                      modelValue: _ctx.nutritionalPlanFormDto.numberOfMeals,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.nutritionalPlanFormDto.numberOfMeals) = $event)),
                      options: _ctx.numberOfMealsOptions,
                      "option-label": "label",
                      "option-value": "value",
                      class: _normalizeClass({
                'no-editable': !_ctx.editMode,
                'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.numberOfMeals.valid,
              })
                    }, null, 8, ["modelValue", "options", "class"])),
                (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.numberOfMeals.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_44, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.numberOfMeals.message}`)), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.editMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
            _createVNode(_component_Button, {
              class: "p-button-secondary p-button-medium",
              label: _ctx.$t('common.cancel'),
              onClick: _ctx.onCancelEdition
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              class: "p-button p-button-info p-button-medium",
              loading: _ctx.waitSubmit,
              label: _ctx.$t('common.save'),
              onClick: _ctx.onSubmit
            }, null, 8, ["loading", "label", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NutritionalPlanConfirmModal, {
      ref: "updateNutritionPlanPatientDataModalRef",
      "nutritional-plan-id": _ctx.nutritionalPlan.id,
      onUpdatedNutritionalPlan: _ctx.updatedNutritionalPlan
    }, null, 8, ["nutritional-plan-id", "onUpdatedNutritionalPlan"])
  ], 64))
}